import Vimeo from '@u-wave/react-vimeo';
import {graphql, PageProps} from 'gatsby';
import React from 'react';
import HeaderImage, {Caption} from '../../components/HeaderImage';
import {Layout} from '../../components/Layout';
import PageWidth from '../../components/PageWidth';
import SEO from '../../components/SEO';
import SoundCloudPlayer from '../../components/SoundCloudPlayer';
import AmpifySoundsLogo from '../../components/svg/AmpifySounds';
import {PageWrapper, RichText} from '../../styles/page';
import renderRichText from '../../utils/rich-text-renderer';
import {getImageFromData, getPageData} from '../../utils';
import {LeeMalcolmQuery} from './__generated__/LeeMalcolmQuery';

const LeeMalcolm = (props: PageProps<LeeMalcolmQuery>) => {
  const data = getPageData(props.data);
  const headerImage = getImageFromData(data, 'headerImage');

  return (
    <Layout>
      <SEO title={data.pageTitle} />
      <HeaderImage src={headerImage}>
        <AmpifySoundsLogo />
      </HeaderImage>
      <Caption caption={data.headerImageCaption} narrow />
      <PageWrapper>
        <PageWidth narrow>
          <RichText>{renderRichText(data.contentTop)}</RichText>
          <Vimeo video={data.topVideoUrl} autoplay={false} responsive={true} />
          <RichText>{renderRichText(data.contentUpperMid)}</RichText>
          <Vimeo
            video={data.lowerVideoUrl}
            autoplay={false}
            responsive={true}
          />
          <RichText>{renderRichText(data.contentLowerMid)}</RichText>
          <SoundCloudPlayer
            title="Lee Malcolm Soundpacks Playlist"
            playlist={data.soundcloudEmbedUrl}
          />
          <RichText>{renderRichText(data.contentBottom)}</RichText>
        </PageWidth>
      </PageWrapper>
    </Layout>
  );
};

export const pageQuery = graphql`
  query LeeMalcolmQuery {
    en: allContentfulAmpifyWebLeeMalcolm(filter: {node_locale: {eq: "en-GB"}}) {
      edges {
        node {
          metaKeywords
          metaDescription
          pageTitle
          headerImage {
            ...AmpifyHeaderImage
          }
          headerImageCaption
          contentTop {
            raw
          }
          topVideoUrl
          contentUpperMid {
            raw
          }
          lowerVideoUrl
          contentLowerMid {
            raw
          }
          soundcloudEmbedUrl
          contentBottom {
            raw
          }
        }
      }
    }
  }
`;

export default LeeMalcolm;
